import { VerticalSliderSlide } from '~/components/ui'

type Props = ComponentProps<'div' | 'header' | 'section'>

export function VerticalSliderSpacer(props: Props) {
  return (
    <VerticalSliderSlide class={props.class ?? ''}>
      {props.children}
    </VerticalSliderSlide>
  )
}
